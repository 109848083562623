<template>
  <!-- 查看来访人员 -->
    <div class="box-card" style="text-align: left;">
      <!-- 主要内容 -->
      <el-form :model="detailsForm" ref="addFormRef" label-width="120px" class="demo-ruleForm">
        <div class="title" style="margin-top: 0;">基本信息</div>
        <el-divider></el-divider>
        <el-row :gutter="70">
          <el-col :span="18">
            <el-form-item label="姓名：">{{detailsForm.visitor_name}}</el-form-item>
            <el-form-item label="手机号：">{{detailsForm.phone}}</el-form-item>
            <el-form-item label="身份证：">{{detailsForm.card_num}}</el-form-item>
            <el-form-item label="来访日期：">{{$moment(detailsForm.visitor_date).format("YYYY-MM-DD")}}</el-form-item>
            <el-form-item label="来访事由：">{{detailsForm.remark}}</el-form-item>
          </el-col>
          <el-col :span="6" style="text-align:right;">
            <el-image style="height: 200px;width: 100%;" :src="detailsForm.register_pic" :preview-src-list="[detailsForm.register_pic]" fit="cover" :z-index="9000">
            </el-image>
            <div class="maskBox">访客图像</div>
          </el-col>
        </el-row>
        <template v-if="detailsForm.audit_status != 4">
          <div class="title" style="margin-top: 0;">接待人</div>
          <el-divider></el-divider>
          <el-form-item label="组织：">{{detailsForm.organize_name}}</el-form-item>
          <el-form-item label="姓名：">{{detailsForm.person_name}}（{{filterPhoneNumber(detailsForm.person_phone)}}）</el-form-item>
        </template>
        <template v-if="detailsForm.permission_group_id != '000000000000000000000000'">
          <div class="title" style="margin-top: 0;">通行权限</div>
          <el-divider></el-divider>
          <el-form-item label="通行权限组：">{{detailsForm.permission_group_name}}</el-form-item>
          <el-form-item label="门禁设备：">
            <el-table size="medium" :data="detailsForm.permission_detail" tooltip-effect="dark" height="170" style="width: 100%" border stripe>
              <el-table-column prop="device_face_name" label="设备名称"></el-table-column>
              <el-table-column prop="permission_type" label="通行规则">
                <template slot-scope="scope">
                  {{getWayName('permissionType', scope.row.permission_type)}}
                </template>
              </el-table-column>
              <el-table-column label="规则详情" min-width="150">
                <template slot-scope="scope">
                  <span v-if="scope.row.permission_type == 1">无限制</span>
                  <span v-else-if="scope.row.permission_type == 2">{{$moment(scope.row.permission_begintime).format("YYYY-MM-DD HH:mm:ss")}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endtime).format("YYYY-MM-DD HH:mm:ss")}}</span>
                  <span v-else-if="scope.row.permission_type == 3">
                    {{$moment(scope.row.permission_beginday).format("YYYY-MM-DD")}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endday).format("YYYY-MM-DD")}}&nbsp;&nbsp;&nbsp;&nbsp;
                    {{$moment(scope.row.permission_begintime).format('HH:mm:ss')}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endtime).format('HH:mm:ss')}}
                  </span>
                  <span v-else-if="scope.row.permission_type == 4">{{scope.row.permission_count}}&nbsp;次</span>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </template>
      </el-form>
    </div>
  </template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getXZVisitorInfoDetail } from '@/api/administrative'
export default {
  props: ['id'],
  data () {
    return {
      detailsForm: {},
      deviceFaceConfigs: [] // 门禁设备列表
    }
  },
  created () {
    this.fngetXZVisitorInfoDetail()
  },
  mounted () { },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取详情
    async fngetXZVisitorInfoDetail () {
      console.log(this.id)
      const res = await getXZVisitorInfoDetail({
        _id: this.id
      })
      console.log(res)
      this.detailsForm = res && res.Code === 200 ? res.Data : {}
    },
    // 获取通行组门禁设备列表
    // async fnGetPermissionGroupDetail(id) {
    //   console.log(id);
    //   const res = await getPermissionGroupDetail({ per_groupid: id });
    //   console.log(res);
    //   if (res && res.Code === 200) {
    //     this.deviceFaceConfigs = res.Data.device_face_configs;
    //     this.detailsForm.permission_group_name = res.Data.permission_group_name;
    //   }
    // },
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    }
  }
}
</script>
  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-top: 50px;
  }
  .demo-ruleForm {
    padding-right: 10px;
    box-sizing: border-box;
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }
  .maskBox {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, .5);
    margin-top: -7px;
  }
  /deep/.el-image__error{
    height: 100%;
    // background-image: url('~@/assets/img/noMessage/noPeopleInLight.png');
    // background-size:contain;
    // background-repeat: no-repeat;
    // background-position: center center;
    // color: transparent;
    // font-size:0;
  }
  </style>
