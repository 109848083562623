<template>
  <div class="box-card" style="text-align: left;" v-loading.fullscreen.lock="isLoading" :element-loading-text="'上传中'"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <!-- 主要内容 -->
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="180px" class="demo-ruleForm">
      <div class="title" style="margin-top: 0;">基本信息</div>
      <el-divider></el-divider>
      <div style="display: flex;justify-content: space-between;">
        <div>
          <el-form-item label="姓名：" prop="visitor_name">
            <el-input v-model="addForm.visitor_name" maxlength="15" placeholder="请输入姓名"
              @input="(e) => (addForm.visitor_name = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="手机号：" prop="phone">
            <el-input maxlength="11" v-model="addForm.phone" placeholder="请输入手机号"
              @input="(e) => (addForm.phone = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="身份证：" prop="card_num">
            <el-input maxlength="18" v-model="addForm.card_num" placeholder="请输入身份证"
              @input="(e) => (addForm.card_num = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="来访日期：" prop="visitor_date">
            <el-date-picker value-format="yyyy-MM-dd" :picker-options="pickerOptions" v-model="addForm.visitor_date"
              type="date" placeholder="请选择来访日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="来访事由：" prop="remark">
            <el-input maxlength="50" v-model="addForm.remark" placeholder="请输入来访事由"
              @input="(e) => (addForm.remark = validSpace(e))"></el-input>
          </el-form-item>
        </div>
        <el-form-item class="shop_img_url" prop="register_pic">
          <div class="img_box">
            <el-upload class="avatar-uploader" accept="image/jpeg,image/jpg,image/png" action="" :http-request="upload"
              :show-file-list="false" :before-upload="beforeAvatarUpload">
              <img v-if="addForm.register_pic" :src="addForm.register_pic" class="avatar" fit="cover">
              <span v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
                上传照片
              </span>
            </el-upload>
            <span>注：该照片用于人脸识别，建议上传最近三个月正面免冠照，图片格式支持jpeg、jpg、png，文件大小小于5M</span>
          </div>
        </el-form-item>
      </div>
      <div class="title" style="margin-top: 0;">通行权限</div>
      <el-divider></el-divider>
      <el-form-item label="组织：" prop="organize_id" class="organizeSelect">
        <el-select popper-class="my-select" v-model="addForm.organize_id" ref="organizeSelect" placeholder="请选择组织"
          clearable filterable @change="organizeChange">
          <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name"
            :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="接待人：" prop="person_id" class="organizeSelect">
        <el-select popper-class="my-select" v-model="addForm.person_id" ref="personSelect" placeholder="请选择接待人" clearable
          filterable @change="personSelectChange" :no-match-text="'www'" >
          <el-option v-for="item in personSelectList" :key="item._id" :label="item.person_name"
            :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通行权限组：" class="organizeSelect">
        <el-select popper-class="my-select" v-model="addForm.permission_group_id" ref="permissionSelect"
          placeholder="请选择通行权限组" clearable filterable @change="fnGetPermissionGroupDetail">
          <el-option v-for="item in permissionGroupSelectList" :key="item._id" :label="item.permission_group_name"
            :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="门禁设备：">
          <el-table size="medium" :data="deviceFaceConfigs" tooltip-effect="dark" style="width: 80%" border stripe>
            <el-table-column prop="device_face_name" label="设备名称"></el-table-column>
            <el-table-column prop="permission_type" label="通行规则">
              <template slot-scope="scope">
                {{getWayName('permissionType', scope.row.permission_type)}}
              </template>
            </el-table-column>
            <el-table-column label="规则详情">
              <template slot-scope="scope">
                <span v-if="scope.row.permission_type == 1">无限制</span>
                <span v-else-if="scope.row.permission_type == 2">{{$moment(scope.row.permission_begintime).format("YYYY-MM-DD HH:mm:ss")}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endtime).format("YYYY-MM-DD HH:mm:ss")}}</span>
                <span v-else-if="scope.row.permission_type == 3">
                  {{$moment(scope.row.permission_beginday).format("YYYY-MM-DD")}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endday).format("YYYY-MM-DD")}}&nbsp;&nbsp;&nbsp;&nbsp;
                  {{$moment(scope.row.permission_begintime).format('HH:mm:ss')}}&nbsp;&nbsp;至&nbsp;&nbsp;{{$moment(scope.row.permission_endtime).format('HH:mm:ss')}}
                </span>
                <span v-else-if="scope.row.permission_type == 4">{{scope.row.permission_count}}&nbsp;次</span>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item> -->
    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm()" v-preventReClick>保 存</lbButton>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import request from '@/api/systemapi'
import { getPermissionGroupSelectList, getXZVisitorInfoPersonsList, getPermissionGroupDetail, addXZVisitorInfo, updateXZVisitorInfo, getXZVisitorInfoDetail } from '@/api/administrative'
import { getOrganizationSeleteList } from '@/api/securityApi'
export default {
  props: ['id'],
  data () {
    return {
      // 加载状态
      isLoading: false,
      addForm: {
        register_pic: '', // 图片
        visitor_name: '', // 访客姓名,
        phone: '', // 手机号
        card_type: 0, // 证件类型（枚举） 默认0
        card_num: '', // 证件号码
        visitor_date: this.$moment().format('YYYY-MM-DD'), // 来访日期
        remark: '', // 来访事由
        organize_id: '', // 组织id
        organize_name: '', // 组织名称
        person_id: '', // 接待人id
        person_name: '', // 接待人姓名
        person_phone: '', // 接待人手机叼
        permission_group_id: '', // 通行权限组id
        permission_group_name: '', // 通行权限组名称
        source_type: 1 // 来源    1:平台添加 2:前台扫码 3:访客机 4:APP 5:公众号
      },
      addFormRules: {
        register_pic: [{ required: true, message: '请上传用户头像', trigger: 'blur' }],
        visitor_name: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
        visitor_date: [{ required: true, message: '请选择来访日期', trigger: 'blur' }],
        phone: [{ required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号格式', trigger: 'blur' }],
        card_num: [{ required: true, pattern: /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/, message: '请输入正确的身份证号', trigger: 'blur' }],
        organize_id: [{ required: true, message: '请选择组织', trigger: 'blur' }],
        person_id: [{ required: true, message: '请选择接待人', trigger: 'blur' }],
        // permission_group_id: [{ required: true, message: '请选择通行权限组', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入来访事由', trigger: 'blur' }]
      },
      organizationSeleteList: [], // 所属组织列表
      permissionGroupSelectList: [], // 通行权限组下拉列表
      personSelectList: [], // 人员下拉列表
      deviceFaceConfigs: [], // 门禁设备列表
      pickerOptions: {
        disabledDate (time) {
          //  如果没有后面的-8.64e6就是不可以选择今天的
          return time.getTime() < Date.now() - 8.64e7
        }
      }
    }
  },
  created () {
    this.fnGetOrganizationSeleteList()
    if (this.id) {
      this.fngetXZVisitorInfoDetail()
    }
  },
  mounted () { },
  computed: {
    ...mapGetters(['getOptions', 'getWayName'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 获取详情
    async fngetXZVisitorInfoDetail () {
      const res = await getXZVisitorInfoDetail({
        _id: this.id
      })
      if (res && res.Code === 200) {
        this.addForm.register_pic = res.Data.register_pic
        this.addForm.visitor_name = res.Data.visitor_name
        this.addForm.visitor_date = this.$moment(res.Data.visitor_date).format('YYYY-MM-DD')
        this.addForm.phone = res.Data.phone
        this.addForm.card_type = res.Data.card_type
        this.addForm.card_num = res.Data.card_num
        this.addForm.remark = res.Data.remark
        this.addForm.organize_id = res.Data.organize_id
        this.addForm.organize_name = res.Data.organize_name
        this.addForm.person_id = res.Data.person_id
        this.addForm.person_name = res.Data.person_name
        this.addForm.person_phone = res.Data.person_phone

        this.addForm.permission_group_id = res.Data.permission_group_name ? res.Data.permission_group_id : ''
        this.addForm.permission_group_name = res.Data.permission_group_name
        this.addForm.source_type = res.Data.source_type
        this.deviceFaceConfigs = res.Data.permission_detail
        this.fnGetPermissionGroupSelectList()
        this.fnGetXZVisitorInfoPersonsList()
        // this.fnGetPermissionGroupDetail(res.Data.permission_group_id)
      }
    },
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizationSeleteList()
      this.organizationSeleteList = res && res.Code === 200 ? res.Data : []
    },
    // 组织下拉列表发生改变时触发
    organizeChange (val) {
      this.addForm.person_id = ''
      this.addForm.permission_group_id = ''
      this.fnGetPermissionGroupSelectList()
      this.fnGetXZVisitorInfoPersonsList()
      this.$nextTick(() => {
        this.addForm.organize_name = this.$refs.organizeSelect.selectedLabel
      })
    },
    personSelectChange (val) {
      var item = this.personSelectList.find(item => item._id === val)
      this.addForm.person_name = item.person_name
      this.addForm.person_phone = item.phone
    },
    // 获取通行组下拉列表
    async fnGetPermissionGroupSelectList () {
      const res = await getPermissionGroupSelectList({ organ_id: this.addForm.organize_id })
      this.permissionGroupSelectList = res && res.Code === 200 ? res.Data : []
      const findePermissionId = this.permissionGroupSelectList.find(item => {
        return item._id === this.addForm.permission_group_id
      })
      // 查找通行权限组列表中有无当前选项
      if (!findePermissionId) {
        this.addForm.permission_group_id = ''
        this.permission_detail = []
      }
    },
    // 获取人员列表
    async fnGetXZVisitorInfoPersonsList () {
      const res = await getXZVisitorInfoPersonsList({ organize_id: this.addForm.organize_id })
      this.personSelectList = res && res.Code === 200 ? res.Data : []
      const findPersonId = this.personSelectList.find(item => {
        return item._id === this.addForm.person_id
      })
      if (!findPersonId) {
        this.addForm.person_id = ''
        this.addForm.person_name = ''
        this.addForm.person_phone = ''
      }
    },
    // 触发选择图片按钮
    upload (item) {
      this.isLoading = true
      const indexpoint = item.file.name.lastIndexOf('.')
      const imageName = item.file.name.substr(indexpoint)
      const src = window.URL.createObjectURL(item.file)
      var reader = new FileReader()
      reader.onload = async (e) => {
        // e.target.result  就是从本地读取的图片的base64格式,将它上传给服务器即可
        // 使用axios的post方法上传即可
        var base64image = e.target.result
        // console.log('==-=-base64image=-=-', base64image)
        this.fnFaceRecognition(base64image, item)
      }
      reader.readAsDataURL(item.file)
    },
    // 人脸验证
    async fnFaceRecognition (base64image, item) {
      const res = await request.faceRecognition({
        img2Base64: base64image
      })
      if (res && res.Code === 200) {
        // this.$msg.success('人脸验证成功！');
        // this.fnupdataIcon(base64image, item)
        if (res.Data.face_count === 1) {
          this.fnupdataIcon(base64image, item)
        } else {
          this.$msg.success('人脸验证失败，存在多张人脸！')
        }
      }
      this.isLoading = false
    },
    async fnupdataIcon (base64image, item) {
      const res = await request.updataIcon({ name: item.file.name, strbase64: [base64image] })
      var url = ''
      if (res.data && res.data.length > 0) {
        url = res.data[0].FilePath
      } else {
        this.$msg.warning('你的图片格式有误请重新选择!')
      }
      this.addForm.register_pic = url
      this.isLoading = false
    },
    // 获取通行组门禁设备列表
    async fnGetPermissionGroupDetail (id) {
      this.$nextTick(() => {
        this.addForm.permission_group_name = this.$refs.permissionSelect.selectedLabel
      })
      if (!id) return this.deviceFaceConfigs = []
      const res = await getPermissionGroupDetail({ per_groupid: id })
      if (res && res.Code === 200) this.deviceFaceConfigs = res.Data.device_face_configs
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      // console.log(this.addForm.cost_time_period_timing);
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          // var obj = this.addForm;
          var obj = {
            register_pic: this.addForm.register_pic,
            visitor_name: this.addForm.visitor_name,
            phone: this.addForm.phone,
            card_type: this.addForm.card_type,
            card_num: this.addForm.card_num,
            visitor_date: this.addForm.visitor_date,
            remark: this.addForm.remark,
            organize_id: this.addForm.organize_id,
            organize_name: this.addForm.organize_name,
            person_id: this.addForm.person_id ? this.addForm.person_id : '000000000000000000000000',
            person_name: this.addForm.person_name,
            person_phone: this.addForm.person_phone,
            source_type: this.addForm.source_type
          }
          if (this.addForm.permission_group_id) {
            obj.permission_group_id = this.addForm.permission_group_id
            obj.permission_group_name = this.addForm.permission_group_name
            var newArr = []
            this.deviceFaceConfigs.forEach((item) => {
            // console.log(item);
              newArr.push({
                device_face_id: item.device_face_id,
                device_face_name: item.device_face_name,
                permission_type: 2,
                permission_begintime: this.addForm.visitor_date + ' 00:00:00',
                permission_endtime: this.addForm.visitor_date + ' 23:59:59',
                permission_count: 0
              })
            })
          }

          obj.permission_detail = newArr
          if (this.id) {
            console.log('编辑')
            obj._id = this.id
            // // 编辑
            this.fnupdateXZVisitorInfo(obj)
          } else {
            // 新增
            this.fnaddXZVisitorInfo(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增请求
    async fnaddXZVisitorInfo (obj) {
      const res = await addXZVisitorInfo(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 编辑请求
    async fnupdateXZVisitorInfo (obj) {
      const res = await updateXZVisitorInfo(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    }
  }
}
</script>
<style scoped lang="less">
.title {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: #000;
  margin-top: 50px;
}

.el-form {
  /deep/ .el-input {
    width: 350px;
  }
}
.organizeSelect{
  /deep/ .el-select{
    width: 350px;
  }
  /deep/ .el-input {
    width: 350px;
  }
}
.shop_img_url {
  /deep/ .el-form-item__content {
    line-height: 1.3;
    margin-left: unset!important;

  }
}

.img_box {
  width: 180px;
  height: 180px;

  /deep/ .avatar-uploader {
    width: inherit;
    height: inherit;
    margin-bottom: 22px;

    .el-upload {
      width: inherit;
      height: inherit;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      >span {
        width: inherit;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
        }
      }
    }

    // .el-upload:hover {
    //   border-color: #409eff;
    // }
  }

  .avatar {
    width: inherit;
    height: inherit;
    object-fit: cover; //重要
    font-family: "object-fit: cover;";
  }
}

.setstyle {
  min-height: 200px;
  padding: 0 !important;
  margin: 0;
  overflow: auto;
  cursor: default !important;
}

.box-card {
  position: relative;
  padding-bottom: 30px;
  .demo-ruleForm{
    overflow: auto;
    padding-right: 20px;
  }

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }

}</style>
