<template>
  <!-- 访客管理 --- 来访人员 -->
  <div style="margin-top: 20px;">
    <div class="box-card" style="text-align: left;">
         <!-- 查询表单 -->
         <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" @submit.native.prevent>
          <el-form-item label="组织：">
            <el-select popper-class="my-select" filterable v-model="queryForm.organize_id" placeholder="请选择组织" clearable @change="onSubmit">
              <el-option v-for="item in organizationSeleteList" :key="item._id" :label="item.organize_name" :value="item._id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名：">
            <el-input maxlength="15" v-model="queryForm.visitor_name" @keyup.enter.native="onSubmit()" placeholder="请输入姓名" @input="(e) => (queryForm.visitor_name = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="手机号：">
            <el-input maxlength="11" v-model="queryForm.phone" @keyup.enter.native="onSubmit()" placeholder="请输入手机号" @input="(e) => (queryForm.phone = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="接待人：">
            <el-input maxlength="15" v-model="queryForm.person_name" @keyup.enter.native="onSubmit()" placeholder="请输入接待人" @input="(e) => (queryForm.person_name = validSpace(e))"></el-input>
          </el-form-item>
          <el-form-item label="来源：">
            <el-select popper-class="my-select" filterable clearable v-model="queryForm.source_type" placeholder="请选择核验类型" @clear="queryForm.source_type = null" @change="onSubmit()">
              <el-option v-for="item in getOptions('VisitorSourceType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
          </el-form-item>
        </el-form>
        <le-card title="来访人员">
          <template slot="heard-right">
            <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('632ab0c4b81f010098002520')">删除</lbButton>
          <lbButton icon="xinzeng" @click="handelVisitorPerson()" v-if="!isAdmin && isShowBtn('631ab0c4b81f010093002520')">新增</lbButton>
        </div>
          </template>
           <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
              <template slot-scope="{row}" slot="register_pic">
                <el-image fit="cover" :src="row.register_pic" :preview-src-list="[row.register_pic]">
              </el-image>
            </template>
              <template slot-scope="{row}" slot="visitor_date">
                {{ validDateTime(row.visitor_date) != '/'  ? $moment(row.visitor_date).format("YYYY-MM-DD"): '/' }}
            </template>
             <template slot-scope="{row}" slot="source_type">
              <span>{{getWayName("VisitorSourceType", row.source_type)}} </span>
            </template>
             <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookVisitorPerson(row._id)"></lbButton>
              <lbButton type="warning" icon="bianji" hint="编辑" @click="handelVisitorPerson(row._id)" v-if="isShowBtn('632ab0c4b81f010093012520')"></lbButton>
            </template>
            </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column label="图像" width="100">
            <template slot-scope="scope">
              <el-image fit="cover" :src="scope.row.register_pic" :preview-src-list="[scope.row.register_pic]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="visitor_name" label="访客"></el-table-column>
          <el-table-column prop="visitor_date" label="来访日期">
            <template slot-scope="scope">
              {{ validDateTime(scope.row.visitor_date) != '/'  ? $moment(scope.row.visitor_date).format("YYYY-MM-DD"): '/' }}
            </template>
          </el-table-column>
          <el-table-column prop="organize_name" label="组织" show-overflow-tooltip></el-table-column>
          <el-table-column prop="remark" label="来访事由"></el-table-column>
          <el-table-column prop="person_name" label="接待人"></el-table-column>
          <el-table-column prop="source_type" label="来源">
            <template slot-scope="scope">
              <span>{{getWayName("VisitorSourceType", scope.row.source_type)}} </span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookVisitorPerson(scope.row._id)"></lbButton>
              <lbButton type="warning" icon="bianji" hint="编辑" @click="handelVisitorPerson(scope.row._id)" v-if="isShowBtn('632ab0c4b81f010093012520')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
        </le-card>
        <!-- 新增编辑来访成员 -->
    <lebo-dialog :title="dialogVisitorPersonId?'编辑来访成员':'新增来访成员'" :isShow="showAddVisitorPerson" width="50%" @close="showAddVisitorPerson = false" footerSlot>
      <addVisitorPerson v-if="showAddVisitorPerson" :id="dialogVisitorPersonId" @closeDialog="closeVisitorPerson"></addVisitorPerson>
    </lebo-dialog>
    <!-- 查看来访人员 -->
    <lebo-dialog title="查看来访人员" :isShow="showLookVisitorPerson" width="50%" @close="showLookVisitorPerson = false" footerSlot closeOnClickModal>
      <lookVisitorPerson v-if="showLookVisitorPerson" :id="dialogVisitorPersonId" @closeDialog="closeVisitorPerson"></lookVisitorPerson>
    </lebo-dialog>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getXZVisitorInfoPageList, delXZVisitorInfo } from '@/api/administrative'
import { getOrganizationSeleteList } from '@/api/securityApi'
import addVisitorPerson from './components/addVisitorPerson/index.vue'
import lookVisitorPerson from './components/lookVisitorPerson/index.vue'
export default {
  components: { addVisitorPerson, lookVisitorPerson },
  data () {
    return {
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        organize_id: '',
        person_name: '', // 接待人姓名
        visitor_name: '', // 访客姓名
        phone: '',
        source_type: null // 来源 0：全部   1:平台添加 2:前台扫码 3:访客机 4:APP 5:公众号
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 组织下拉列表
      organizationSeleteList: [],
      dialogVisitorPersonId: '',
      showAddVisitorPerson: false,
      showLookVisitorPerson: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '图像',
            prop: 'register_pic',
            slot: true
          }, {
            label: '访客',
            prop: 'visitor_name'
          }, {
            label: '来访日期',
            prop: 'visitor_date',
            slot: true
          }, {
            label: '组织',
            prop: 'organize_name'
          }, {
            label: '来访事由',
            prop: 'remark'
          }, {
            label: '接待人',
            prop: 'person_name'
          }, {
            label: '来源',
            prop: 'source_type',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetOrganizationSeleteList()
    this.fnGetXZVisitorInfoPageList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取组织下拉列表
    async fnGetOrganizationSeleteList () {
      const res = await getOrganizationSeleteList()
      this.organizationSeleteList = res && res.Code === 200 ? res.Data : []
    },
    // 获取分页数据列表
    async fnGetXZVisitorInfoPageList () {
      const res = await getXZVisitorInfoPageList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        organize_id: this.queryForm.organize_id,
        person_name: this.queryForm.person_name,
        visitor_name: this.queryForm.visitor_name,
        phone: this.queryForm.phone,
        source_type: this.queryForm.source_type ? this.queryForm.source_type : 0
      })
      this.total = res && res.Code === 200 ? res.Data.TotalCount : 0
      this.tableData = res && res.Code === 200 ? res.Data.DataList : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetXZVisitorInfoPageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetXZVisitorInfoPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetXZVisitorInfoPageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      // console.log(`当前页: ${val}`);
      this.fnGetXZVisitorInfoPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetXZVisitorInfoPageList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的人员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // console.log('aaaa');
          // this.$msg.success('删除成功!');
          var idArr = []
          this.multipleSelection.forEach((item) => {
            idArr.push(item._id)
          })
          this.fnDelXZVisitorInfo({ _id: idArr.join(',') })
        }).catch(() => {
          // console.log('catch---');
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的人员！')
      }
    },
    async fnDelXZVisitorInfo (obj) {
      console.log('删除请求')
      const res = await delXZVisitorInfo(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetXZVisitorInfoPageList()
    },
    // 新增编辑来访人员
    handelVisitorPerson (id) {
      this.dialogVisitorPersonId = id
      this.showAddVisitorPerson = true
    },
    lookVisitorPerson (id) {
      this.dialogVisitorPersonId = id
      this.showLookVisitorPerson = true
    },
    closeVisitorPerson () {
      this.showAddVisitorPerson = false
      this.showLookVisitorPerson = false
      this.fnGetXZVisitorInfoPageList()
    }
  }
}
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}
.el-image {
  width: 55px;
  height: 55px;
  /deep/.el-image__error{
    background-image: url('~@/assets/img/noMessage/noPeopleInLight.png');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    font-size:0;
  }
}
/deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
  width: 200px!important;
}
</style>
